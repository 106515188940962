<template>
  <div>
    <div class="">
      <div

        class="gallery width-100 border-solid border outline-none focus:outline-none border-white  border-opacity-20 rounded shadow"
        :class="error?'red-border':''"
      >
        <div
          class="elements-wraper cursor-pointer"
          style="min-height: 130px;"
          @dragover.prevent="dragover($event)"
          @drop.prevent="drop($event)"
        >
          <!--UPLOAD BUTTON-->

          <div
            v-if="!hideUpload"
            :class="isSidebar? 'button-container-sidebar': 'button-container'"
          >
            <label
              for="images-upload"
              :class="isSidebar ? 'images-upload-sidebar' : 'images-upload'"
            >

              <span
                style="display: inline-block; vertical-align: middle; color: rgb(66,219,192); line-height: normal;"
                class="material-icons custum-icon cursor-pointer text-center mt-2"
                @click="showFormDetail = true"
              >
                cloud_upload
              </span>

            </label>
            <input
              id="images-upload"
              type="file"
              accept="image/*"
              multiple
              hidden
              @change="fileChange"
            >
          </div>
          <div
            v-else-if="!media.length"
            class="w-full h-full font-bold text-white text-center mb-5"
          >
            <p>No Files Attached</p>
          </div>

          <!--IMAGES PREVIEW-->

          <div
            v-for="(image, index) in media"
            :key="index"
            :class="index < 2 && isSidebar? 'mt-5 m-1 image-container-sidebar': isSidebar ? 'image-container-sidebar m-1' : 'mx-2 image-container' "
            @mouseover="image.isHoverActive = true"
            @mouseleave="image.isHoverActive = false"
          >
            <img
              :style="image.isHoverActive ? 'opacity: 0.2': 'opacity: 1'"
              :src="image.url"
              alt=""
              class="images-preview"
            >
            <button
              v-if="image.isHoverActive"
              class="close-btn"
              type="button"
              :style="isSidebar? 'right: 20%;': 'right: 42%;'"
              @mouseover="image.isHoverActive = true"
            >
              <span
                style="display: inline-block; float: right;  vertical-align: middle; color: rgb(255, 99, 71);  margin-top: 50px; line-height: normal;"
                class="material-icons cursor-pointer"
                @click="remove(index)"
              >
                delete
              </span>
            </button>

            <button
              v-if="image.isHoverActive && isSidebar"
              class="close-btn"
              type="button"
              style="left: -60%;"
              @mouseover="image.isHoverActive = true"
            >
              <span
                style="display: inline-block; float: right; vertical-align: middle; color: rgb(225, 225, 225); margin-top: 50px; line-height: normal;"
                class="material-icons cursor-pointer"
                @click="downloadURI(image)"
              >
                cloud_download
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="error"
        id="media-required"
      >
        <p class="red-text">
          {{ error }}
        </p>
      </div>
      <div
        v-for="(image, index) in media"
        :key="index"
        class="m-top"
      >
        <input
          type="text"
          name="media[]"
          :value="image.name"
          hidden
        >
      </div>
    </div>
  </div>
</template>

<script>

import { getComponent, getConfigEnv, getView } from '@/utils/helpers';
import { IDENTITY_POOL_ID, SCHEDULE_BUCKET_NAME, SCHEDULE_BUCKET_REGION } from '@/lib/constants';
import AWS from 'aws-sdk';
import accountModule from '@/store/modules/accountModule';

export default {
  props: {
    error: '',
    hideUpload: false,
    isSidebar: {
      default: false,
      type: Boolean,
    },
    files: {
      default: [],
      type: Array,
    },
  },
  data() {
    return {
      media: [],
      loading: false,
      isHoverActive: false,
    };
  },
  mounted() {
    console.log(this.files);
    console.log('created');
    if (this.files && this.files.length) {
      this.media = this.files;
    } else {
      this.$emit('media', this.media);
    }
  },
  methods: {
    async fileChange(event) {
      this.loading = true;
      const { files } = event.target;
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        const url = URL.createObjectURL(files[i]);
        formData.set('image', files[i]);
        console.log(formData);
        console.log(url);
        console.log(files[i]);
        // const { data } = await axios.post(this.server, formData);

        // this.media.push({
        //   url, name: files[i].name, size: files[i].size, type: files[i].type, isHoverActive: false,
        // });
        this.processFileUpload(files[i])
      }
      this.loading = false;
      // this.media_emit();
    },
    remove(index) {
      this.media.splice(index, 1);
      this.media_emit();
    },
    media_emit() {
      this.$emit('media', this.media);
    },

    dragover(e) {
      e.stopPropagation();
      e.dataTransfer.dropEffect = 'copy';
    },
    drop(e) {
      e.stopPropagation();
      this.createFile(e);
    },
    createFile(e) {
      const files = [...e.target.files || e.dataTransfer.files]; // Array of all files
      for (let i = 0; i < files.length; i++) {
        // const formData = new FormData();
        const url = URL.createObjectURL(files[i]);
        // formData.set('image', files[i]);
        // const { data } = await axios.post(this.server, formData);
        this.processFileUpload(files[i]);
      }
    },
    downloadURI({ url, name }) {
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    processFileUpload(file) {
      const extension = file.name.split('.').pop();

      const operator = getConfigEnv('OPERATOR_LOWERCASED');
      this.$emit('set-saving-details-banner-id', `Uploading "${file.name}"`);

      AWS.config.update({
        region: SCHEDULE_BUCKET_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IDENTITY_POOL_ID,
        }),
      });

      const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: {
          Bucket: SCHEDULE_BUCKET_NAME,
        },
      });

      const key = `${operator}/workTickets/${accountModule.user.email.toLowerCase()}/${file.name}`;
      if (extension !== 'pdf') {
        s3.upload({
          Key: key,
          Bucket: SCHEDULE_BUCKET_NAME,
          Body: file,
          ACL: 'public-read',
        },
        (err, data) => {
          if (err) {
            console.log(err, 'there was an error uploading your file');
          } else {
            const s3_url = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;
            this.media.push({
              url: s3_url, name: file.name, size: file.size, type: file.type, isHoverActive: false,
            });
            this.media_emit();
          }
        });
      } else if (extension == 'pdf') {
        s3.upload({
          Key: key,
          Bucket: SCHEDULE_BUCKET_NAME,
          Body: file,
          ContentDisposition: 'inline',
          ACL: 'public-read',
          ContentType: 'application/pdf',
        },
        (err, data) => {
          if (err) {
            console.log(err, 'there was an error uploading your file');
          } else {
            const s3_url = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;

            this.media.push({
              url: s3_url, name: file.name, size: file.size, type: file.type, isHoverActive: false,
            });
            this.media_emit();
          }
        });
      }
    },
  },

};
</script>

<style scoped>
.image-wraper{
    min-height: 200px !important;

}
.gallery{
    background: transparent;
    height: 85px !important;
    line-height: 1 !important;
    box-sizing: border-box !important;
    height: auto !important;
}
.images-upload {
    background-color:#334861;
    border-radius: 5px;
    border: 1px dashed #ccc;
    display: inline-block;
    cursor: pointer;
    text-align: center !important;
    width: 165px;
    height: 88px;
}

.images-upload-sidebar {
    background-color:#334861;
    border-radius: 5px;
    border: 1px dashed #ccc;
    display: inline-block;
    cursor: pointer;
    text-align: center !important;
    width: 100%;
    height: 88px;
}

.images-upload:hover{
    background-color: #334861 !important;
    filter: brightness(80%);
}
.image-container{
    display: inline-table !important;
    height: 90px !important;
    width: 140px !important;
    display: flex !important;
}

.image-container-sidebar{
    display: inline-table !important;
    height: 90px !important;
    width: 46% !important;
    display: flex !important;
}

.images-preview {
    border-radius: 5px !important;
    border: 1px solid #ccc !important;
    display: inline-block !important;
    width: 100% !important;
    height: 88px !important;
    padding-top: -14px !important;
    transition: filter 0.1s linear;

}
.images-preview:hover{
    filter: brightness(90%);
}
.button-container{
    display: inline-flex !important;
    height: 90px !important;
    width: 140px !important;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.button-container-sidebar{
    display: inline-flex !important;
    height: 90px !important;
    width: 100% !important;
}
.close-btn{
    background: none !important;
	color:red !important;
	border: none !important;
	padding: 0px !important;
    margin:0px !important;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
    position: relative !important;
    top: -27px !important;
    width: 0px !important;
}
.times-icon{
    font-size: 3rem !important;
    padding: 0px !important;
    margin:0px !important;
}
.custum-icon{
    color: rgb(66,219,192) !important;
    font-size: 3rem !important;
    margin-top: 18px !important;

}
.custum-icon:hover{
    color: rgb(66,219,192) !important;
}
.close-btn:hover{
    color: rgb(190, 39, 39) !important;
}
/* -------------------- */
.width-100 {
  width: 100% !important;
}
.red-border {
    border: 1px solid #dc3545 !important;
    border-color: #dc3545 !important;
}
.elements-wraper {
  padding: 1rem !important;
  display: flex !important;
  flex-wrap: wrap !important;
}
.align-center {
  text-align: center !important;
}
.m-top-1 {
  margin-top: 0.25rem !important;
}

.red-text {
    color: #d82335;
}
</style>
